<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vx-card class="mb-base">
          <div class="vx-row" id="invoice-detail">
            <div class="vx-col flex-1" id="account-info-col-1">
              <table>
                <tr>
                  <td class="font-semibold">
                    Name
                  </td>
                  <td>{{ customer.name }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Phone
                  </td>
                  <td>{{ customer.phone }}</td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Member since
                  </td>
                  <td>
                    {{ customer.date }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="vx-col flex-1" id="account-info-col-2">
              <table>
                <tr>
                  <td class="font-semibold">
                    Total Transaction
                  </td>
                  <td>
                    <vs-chip color="primary">
                      {{ customer.total_transaction }}
                    </vs-chip>
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    Total Items
                  </td>
                  <td>
                    <vs-chip color="primary">
                      {{ items.length }}
                    </vs-chip>
                  </td>
                </tr>
                <tr>
                  <!-- update customer button -->
                  <td>
                    <vs-button
                      @click="customerModal.is_active = true"
                      color="warning"
                      type="filled"
                      size="small"
                    >
                      Update
                    </vs-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mx-auto self-center">
        <vx-card>
          <div class="vs-con-loading__container" id="div-with-loading">
            <vs-table stripe :data="items" @selected="loadEditModal">
              <template slot="thead">
                <vs-th>Item</vs-th>
                <vs-th>Material</vs-th>
                <vs-th>Color</vs-th>
                <vs-th>Size</vs-th>
                <vs-th>Lace</vs-th>
                <vs-th>Insole</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                  <vs-td :data="tr.brand">
                    {{ tr.brand }} {{ tr.model }} ({{ tr.type }})
                  </vs-td>

                  <vs-td :data="tr.material">
                    {{ tr.material || '-' }}
                  </vs-td>

                  <vs-td :data="tr.color">
                    {{ tr.color || '-' }}
                  </vs-td>

                  <vs-td :data="tr.size">
                    {{ tr.size ||'-' }}
                  </vs-td>

                  <vs-td :data="tr.lace">
                    {{ tr.lace ||'-' }}
                  </vs-td>

                  <vs-td :data="tr.insole">
                    {{ tr.insole ||'-' }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div slot="footer">
            <vs-button color="primary" type="filled" to="/customers">
              Back
            </vs-button>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-popup
      class="holamundo"
      title="Update item"
      :active.sync="itemModal.is_active"
    >
      <div
        class="vx-row"
        data-vv-scope="item-modal"
      >
        <div
          class="vx-col w-full mt-5"
        >
          <label class="vs-input--label">Type</label>
          <multiselect
            :class="{ 'is-danger': errors.has('type') }"
            v-validate="'required'"
            name="type"
            placeholder="Select type"
            label="name"
            track-by="name"
            :custom-label="({ name }) => capitalize(name)"
            :searchable="true"
            v-model="itemModal.form.type"
            :options="fillables.types"
            @keydown.tab="console.log('WHAT THE FUCK')"
          >
            <div slot="noResult">
              Type not found
            </div>
          </multiselect>
          <div
            v-if="errors.has('type')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('type') }}
            </span>
          </div>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Brand</label>
          <multiselect
            @search-change="(val) => loadFillables('brand', val, 'brands')"
            placeholder="Select brand"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-validate="'required'"
            name="brand"
            v-model="itemModal.form.brand"
            :options="fillables.brands"
            :taggable="true"
            :max-height="200"
            :class="{ 'is-danger': errors.has('brand') }"
            @tag="(val) => addTag(val, 'brand')"
            :loading="loading.brand"
            tag-placeholder="Add new brand"
          >
            <div slot="noOptions">
              Type brand name
            </div>
          </multiselect>
          <div
            v-if="errors.has('brand')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('brand') }}
            </span>
          </div>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Model</label>
          <multiselect
            @search-change="(val) => loadFillables('model', val, 'models')"
            placeholder="Select model"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-validate="'required'"
            name="model"
            v-model="itemModal.form.model"
            :options="fillables.models"
            :taggable="true"
            :max-height="200"
            :class="{ 'is-danger': errors.has('model') }"
            @tag="(val) => addTag(val, 'model')"
            :loading="loading.model"
            tag-placeholder="Add new model"
          >
            <div slot="noOptions">
              Type model name
            </div>
          </multiselect>
          <div
            v-if="errors.has('model')"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;"
          >
            <span class="span-text-validation">
              {{ errors.first('model') }}
            </span>
          </div>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Material</label>
          <multiselect
            @search-change="(val) => loadFillables('material', val, 'materials')"
            placeholder="Select material"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.material"
            :options="fillables.materials"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'material')"
            :loading="loading.material"
            tag-placeholder="Add new material"
          >
            <div slot="noOptions">
              Type material name
            </div>
          </multiselect>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Color</label>
          <multiselect
            @search-change="(val) => loadFillables('color', val, 'colors')"
            placeholder="Select color"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.color"
            :options="fillables.colors"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'color')"
            :loading="loading.color"
            tag-placeholder="Add new color"
          >
            <div slot="noOptions">
              Type color name
            </div>
          </multiselect>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <label class="vs-input--label">Lace</label>
          <multiselect
            @search-change="(val) => loadFillables('lace', val, 'laces')"
            placeholder="Select lace"
            label="name"
            track-by="name"
            :custom-label="({ name }) => name"
            :searchable="true"
            v-model="itemModal.form.lace"
            :options="fillables.laces"
            :taggable="true"
            :max-height="200"
            @tag="(val) => addTag(val, 'lace')"
            :loading="loading.lace"
            tag-placeholder="Add new lace"
          >
            <div slot="noOptions">
              Type lace name
            </div>
          </multiselect>
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <vs-input
            val-icon-danger="clear"
            name="size"
            :danger="errors.has('size')"
            :danger-text="errors.first('size')"
            v-validate="'min:1|max:15'"
            size="normal"
            label="Size"
            v-model="itemModal.form.size"
            class="w-full"
          />
        </div>
        <div
          class="vx-col w-1/2 mt-5"
        >
          <vs-input
            val-icon-danger="clear"
            name="insole"
            :danger="errors.has('insole')"
            :danger-text="errors.first('insole')"
            v-validate="'min:1|max:50'"
            size="normal"
            label="Insole"
            v-model="itemModal.form.insole"
            class="w-full"
          />
        </div>
        <vs-divider />
        <div class="vx-col w-full mt-5">
          <div class="flex justify-between">
            <vs-button
              @click="saveItem"
              color="success"
              type="filled"
            >
              Save Item
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="holamundo"
      title="Update customer"
      :active.sync="customerModal.is_active"
    >
      <div
        class="vx-row"
        data-vv-scope="customer-modal"
      >
        <div
          class="vx-col w-full"
        >
          <vs-input
            val-icon-danger="clear"
            name="name"
            :danger="errors.has('name')"
            :danger-text="errors.first('name')"
            v-validate="'required|min:2|max:50'"
            size="normal"
            label="Name"
            v-model="customerModal.form.name"
            class="w-full"
          />
        </div>
        <div
          class="vx-col w-full mt-5"
        >
          <vs-input
            val-icon-danger="clear"
            name="phone"
            :danger="errors.has('phone')"
            :danger-text="errors.first('phone')"
            v-validate="'required|numeric|min:10|max:20'"
            size="normal"
            label="Phone"
            v-model="customerModal.form.phone"
            class="w-full"
          />
        </div>
        <div class="vx-col w-full mt-5">
          <div class="flex justify-between">
            <vs-button
              :disabled="errors.items.length !== 0"
              @click="saveCustomer"
              color="success"
              type="filled"
              block
            >
              Save Customer
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment';
import CustomerRepo from '@/repositories/customer_repo';
import FillableRepo from '@/repositories/fillable_repo';
import ItemRepo from '@/repositories/item_repo';

export default {
  data: () => ({
    customer: {
      id: null,
      name: null,
      phone: null,
      date: null,
      total_transaction: 0,
      total_item: 0
    },
    items: [],
    itemModal: {
      is_active: false,
      form: {
        item_id: null,
        type: null,
        brand: null,
        model: null,
        material: null,
        size: null,
        color: null,
        lace: null,
        insole: null
      }
    },
    fillables: {
      customers: [],
      treatments: [],
      types: [{ name: 'Shoes' }, { name: 'Bag' }, { name: 'Accessories' }],
      brands: [],
      models: [],
      materials: [],
      colors: [],
      laces: []
    },
    loading: {
      customer: false,
      brand: false,
      model: false,
      material: false,
      color: false,
      lace: false,
      item: false,
      page: false
    },
    customerModal: {
      form: {
        name: null,
        phone: null
      },
      is_active: false
    }
  }),
  methods: {
    async loadData() {
      return CustomerRepo.detail(this.$route.params.id)
        .then(({ data: content }) => {
          this.customer = {
            id: content.id,
            name: content.name,
            phone: content.phone,
            date: moment(content.created_at).format('DD / MM / YYYY'),
            total_transaction: content.total_transaction
          };
          this.customerModal.form = {
            name: content.name,
            phone: content.phone
          };
          return content.id;
        })
        .then((id) => {
          this.loading.page = true;
          return ItemRepo.get(id);
        })
        .then(({ data: { data: items } }) => {
          this.items = items;
          this.loading.page = false;
        });
    },
    loadFillables (type, value) {
      const collection = `${type}s`;
      if (value.length !== 0) {
        this.loading[type] = true;
        return FillableRepo.get(type, value, collection)
          .then(({ data: { data: fillables } }) => {
            this.fillables[collection] = fillables.map(item => ({ name: this.capitalize(item.value) }));
            this.loading[type] = false;
          });
      }
      this.fillables[collection] = [];
    },
    capitalize(value) {
      const splitted = value.split('');
      splitted[0] = splitted[0].toUpperCase();
      return splitted.join('');
    },
    loadEditModal({
      brand, model, material, size, color, lace, type, id: itemId, insole
    }) {
      this.itemModal.form = {
        type: { name: type },
        brand: { name: brand },
        material: { name: material },
        model: { name: model },
        color: { name: color },
        lace: { name: lace },
        size,
        item_id: itemId,
        insole
      };
      this.itemModal.is_active = true;
    },
    addTag (newTag, field) {
      const collections = `${field}s`;
      const tag = { name: newTag };
      this.fillables[collections].push(tag);
      this.itemModal.form[field] = tag;
    },
    async saveItem() {
      const data = this.itemModal.form;
      const payload = {
        type: data.type.name,
        brand: data.brand.name,
        model: data.model.name,
        material: data.material && data.material.name,
        color: data.color && data.color.name,
        size: data.size,
        lace: data.lace && data.lace.name,
        insole: data.insole
      };
      await ItemRepo.update(this.itemModal.form.item_id, payload)
        .then(() => {
          this.itemModal.is_active = false;
          return this.loadData();
        });
    },
    async saveCustomer() {
      const { name, phone } = this.customerModal.form;
      await CustomerRepo.update(this.customer.id, { name, phone })
        .then(() => {
          this.customerModal.is_active = false;
          return this.loadData();
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Whoops',
            text: err.message,
            color: 'danger',
            position: 'bottom-center'
          });
        });
    }
  },
  async mounted() {
    await this.loadData();
  },
  watch: {
    'loading.page': function (isLoading) {
      if (isLoading) {
        this.$vs.loading({ container: '#div-with-loading', scale: 0.6 });
      } else {
        this.$vs.loading.close('#div-with-loading > .con-vs-loading');
      }
    }
  }
};
</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#invoice-detail {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

}

</style>
